import PropTypes from 'prop-types';
import ProductPromotionDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/ProductPromotionDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const ProductPromotionModule = ({ isGrid = false, content, ...rest }) => {
    const [css] = useStyletron();

    const settings = {
        'grid': {
            imageSettings: {
                width: [200, 300, 400, 500, 600],
                sizes: ['50vw', null, null, '33vw', '25vw'],
            },
        },
        'slider': {
            slidesPerView: [1.4, null, null, 3, 4],
            imageSettings: {
                width: [200, 300, 400, 500, 600],
                sizes: ['75vw', null, null, '33vw', '25vw'],
            },
        },
    };

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)' })}>
            <div
                className={css({
                    maxWidth: 'var(--site-corridor-max-width)',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                })}
            >
                <ProductPromotionDataTransformerBlock content={content} {...settings[content.layout]} {...rest} />
            </div>
        </section>
    );
};

ProductPromotionModule.propTypes = {
    content: PropTypes.object.isRequired,
    isGrid: PropTypes.bool,
};

export default ProductPromotionModule;
